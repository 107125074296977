import md5 from 'js-md5' //引入MD5加密
import axios from 'axios'
const concurrentExecution = function (list, limit, asyncHandle) {
    // 递归执行
    let recursion = (arr) => {
        // 执行方法 arr.shift() 取出并移除第一个数据
        return asyncHandle(arr.shift()).then(() => {
            // 数组还未迭代完，递归继续进行迭代
            if (arr.length !== 0) {
                return recursion(arr)
            } else {
                return 'finish'
            }
        })
    }
    // 创建新的并发数组
    let listCopy = [].concat(list)
    // 正在进行的所有并发异步操作
    let asyncList = []
    limit = limit > listCopy.length ? listCopy.length : limit
    while (limit--) {
        asyncList.push(recursion(listCopy))
    }
    // 所有并发异步操作都完成后，本次并发控制迭代完成
    return Promise.all(asyncList)
}
/**
 * 文件分片上传
 * @params file {File} 文件
 * @params pieceSize {Number} 分片大小 默认3MB
 * @params concurrent {Number} 并发数量 默认2
 * @params success {Function} 成功回调函数
 *  @params process {Function} 进度回调函数
 * @params error {Function} 失败回调函数
 */
export default function uploadByPieces(
    timestamp,
    file,
    pieceSize = 3,
    concurrent = 3,
    success,
    process,
    error
) {
    // 如果文件传入为空直接 return 返回
    if (!file || file.length < 1) {
        return error('文件不能为空')
    }
    let fileMD5 = '' // 总文件列表
    const chunkSize = pieceSize * 1024 * 1024 // 3MB一片
    const chunkCount = Math.ceil(file.size / chunkSize) // 总片数
    const chunkList = [] // 分片列表
    let uploaded = [] // 已经上传的
    let fileType = '' // 文件类型
    let uploadId = '' // 上传id
    let maxFileSize = 1024 * 1024 * 5
    let offset = 0
    /***
     * 获取md5
     **/
    const readFileMD5 = () => {
        // 读取视频文件的md5
        fileType = file.name.substring(
            file.name.lastIndexOf('.') + 1,
            file.name.length
        )
        let fileRederInstance = new FileReader()
        if (maxFileSize < file.size) {
            const slice = file.slice(offset, offset + maxFileSize)
            fileRederInstance.readAsArrayBuffer(slice)
        } else {
            fileRederInstance.readAsBinaryString(file)
        }
        fileRederInstance.addEventListener('load', (e) => {
            let fileBolb = e.target.result
            fileMD5 = md5(fileBolb)
            let form = new FormData()
            form.append('filename', file.name)
            form.append('identifier', timestamp + fileMD5)
            form.append('objectType', fileType)
            form.append('chunkNumber', 1)
            form.append('uploadId', uploadId)
            uploadChunks(form)
                .then((res) => {
                    if (res.data.skipUpload) {
                        success && success(res)
                    } else {
                        uploadId = res.data.uploadId
                        // 判断是否是断点续传
                        if (
                            res.data.uploaded &&
                            res.data.uploaded.length != 0
                        ) {
                            uploaded = [].concat(res.data.uploaded)
                        }
                        // 判断是并发上传或顺序上传
                        if (concurrent == 1 || chunkCount == 1) {
                            sequentialUplode(0)
                        } else {
                            concurrentUpload()
                        }
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
        })
    }
    /***
     * 获取每一个分片的详情
     **/
    const getChunkInfo = (file, currentChunk, chunkSize) => {
        let start = currentChunk * chunkSize
        let end = Math.min(file.size, start + chunkSize)
        let chunk = file.slice(start, end)
        return {
            start,
            end,
            chunk,
        }
    }
    /***
     * 针对每个文件进行chunk处理
     **/
    const readChunkMD5 = () => {
        // 针对单个文件进行chunk上传
        for (var i = 0; i < chunkCount; i++) {
            const { chunk } = getChunkInfo(file, i, chunkSize)

            // 判断已经上传的分片中是否包含当前分片
            if (uploaded.indexOf(i + '') == -1) {
                uploadChunk({
                    chunk,
                    currentChunk: i,
                    chunkCount,
                })
            }
        }
    }
    /***
     * 原始上传
     **/
    const uploadChunk = (chunkInfo) => {
        var sd = parseInt((chunkInfo.currentChunk / chunkInfo.chunkCount) * 100)
        process(sd)
        let inde = chunkInfo.currentChunk + 1
        if (uploaded.indexOf(inde + '') > -1) {
            const { chunk } = getChunkInfo(
                file,
                chunkInfo.currentChunk + 1,
                chunkSize
            )
            uploadChunk({
                chunk,
                currentChunk: inde,
                chunkCount,
            })
        } else {
            let uploadData = createUploadData(chunkInfo)
            upload(uploadData)
                .then((res) => {
                    if (res.code == 200) {
                        uploaded.push(chunkInfo.currentChunk + 1)
                        // 判断是否全部上传完
                        if (uploaded.length == chunkInfo.chunkCount) {
                            success(res)
                            process(100)
                        } else {
                            const { chunk } = getChunkInfo(
                                file,
                                chunkInfo.currentChunk + 1,
                                chunkSize
                            )
                            uploadChunk({
                                chunk,
                                currentChunk: chunkInfo.currentChunk + 1,
                                chunkCount,
                            })
                        }
                    } else {
                        console.log(res.msg)
                    }
                })
                .catch((e) => {
                    error && error(e)
                })
        }
    }
    /***
     * 顺序上传
     **/
    const sequentialUplode = (currentChunk) => {
        return new Promise((uploadResolve, uploadReject) => {
            const { chunk } = getChunkInfo(file, currentChunk, chunkSize)
            let chunkInfo = {
                chunk,
                currentChunk,
                chunkCount,
                uploadId,
            }
            var sd = parseInt(
                (chunkInfo.currentChunk / chunkInfo.chunkCount) * 100
            )
            process(sd)
            let inde = chunkInfo.currentChunk + 1
            if (uploaded.indexOf(inde + '') > -1) {
                sequentialUplode(currentChunk + 1)
                    .then(() => {
                        uploadResolve()
                    })
                    .catch((e) => {
                        uploadReject(e)
                    })
            } else {
                let uploadData = createUploadData(chunkInfo)
                // 执行分片上传
                uploadChunks(uploadData)
                    .then((res) => {
                        if (res.code == 200) {
                            uploaded.push(chunkInfo.currentChunk + 1)
                            // 判断是否全部上传完
                            if (uploaded.length == chunkInfo.chunkCount) {
                                success(res)
                                process(100)
                                uploadResolve()
                            } else {
                                sequentialUplode(currentChunk + 1)
                                    .then(() => {
                                        uploadResolve()
                                    })
                                    .catch((e) => {
                                        uploadReject(e)
                                    })
                            }
                        } else {
                            console.log(res.msg)
                            uploadReject(res.msg)
                        }
                    })
                    .catch((e) => {
                        error && error(e)
                        uploadReject(e)
                    })
            }
        })
    }
    /***
     * 并发上传
     **/
    const concurrentUpload = () => {
        return new Promise((uploadResolve, uploadReject) => {
            for (var i = 0; i < chunkCount; i++) {
                let index = Number(i) + 1
                if (uploaded.indexOf(index) === -1) {
                    chunkList.push(Number(i))
                }
            }
            concurrentExecution(chunkList, concurrent, (curItem) => {
                return new Promise((resolve, reject) => {
                    const { chunk } = getChunkInfo(file, curItem, chunkSize)
                    let chunkInfo = {
                        chunk,
                        currentChunk: curItem,
                        chunkCount,
                    }
                    var sd = parseInt(
                        (chunkInfo.currentChunk / chunkInfo.chunkCount) * 100
                    )
                    process(sd)
                    let inde = chunkInfo.currentChunk + 1
                    if (uploaded.indexOf(inde) == -1) {
                        // 构建上传文件的formData
                        let uploadData = createUploadData(chunkInfo)
                        uploadChunks(uploadData)
                            .then((res) => {
                                if (res.code == 200) {
                                    uploaded.push(chunkInfo.currentChunk + 1)
                                    // 判断是否全部上传完
                                    if (
                                        uploaded.length == chunkInfo.chunkCount
                                    ) {
                                        success(res)
                                        process(100)
                                    }
                                    resolve()
                                } else {
                                    reject(res)
                                    console.log(res.msg)
                                }
                            })
                            .catch((e) => {
                                console.log(e)
                                reject(e)
                                error && error(e)
                            })
                    } else {
                        resolve()
                    }
                })
            })
                .then((res) => {
                    console.log('finish', res)
                    uploadResolve()
                })
                .catch((e) => {
                    uploadReject(e)
                })
        })
    }
    /***
     * 创建文件上传参数
     **/
    const createUploadData = (chunkInfo) => {
        let fetchForm = new FormData()
        fetchForm.append('identifier', timestamp + fileMD5)
        fetchForm.append('chunkNumber', chunkInfo.currentChunk + 1)
        fetchForm.append('chunkSize', chunkSize)
        fetchForm.append('currentChunkSize', chunkInfo.chunk.size)
        const chunkfile = new File([chunkInfo.chunk], file.name)
        fetchForm.append('file', chunkfile)
        fetchForm.append('filename', file.name)
        fetchForm.append('relativePath', file.name)
        fetchForm.append('totalChunks', chunkInfo.chunkCount)
        fetchForm.append('totalSize', file.size)
        fetchForm.append('objectType', fileType)
        fetchForm.append('uploadId', uploadId)
        return fetchForm
    }
    const api = axios.create({
        baseURL: 'https://api.biaoshisx.com',
        timeout: 1000 * 60 * 60 * 4,
    })
    const uploadChunks = (data) => {
        console.log(data, 'chunks给后台的参数')
        return new Promise((resolve, reject) => {
            api({
                url: '/file/upload-chunks',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                data: data,
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((e) => {
                    reject(e)
                })
        })
    }

    const upload = (data) => {
        return new Promise((resolve, reject) => {
            api({
                url: '/file/upload',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                data: data,
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((e) => {
                    reject(e)
                })
        })
    }
    readFileMD5() // 开始执行代码
}
